import * as React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import '../../styles/management.scss';

const MontyBayer = () => {
  return (
    <Layout>
      <SEO
        title="MONTY BAYER - Management | Ostara"
        description="NUTRIENT MANAGEMENT SOLUTIONS"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main className="management">
        <Container
          className="management-details-container"
          w="90%"
          paddingBottom={30}
          maxW={1694}
        >
          <Box className="manager-details">
            <div className="manager-details__text">
              <Link className="manager-details__back" to="/management">
                <ArrowBackIcon color="#044606" />
                BACK
              </Link>
              <StaticImage
                className="manager-details__img mobile"
                placeholder="transparent"
                src="../../images/monty.jpg"
                alt=""
              />
              <Heading
                as="h2"
                color="#044606"
                className="manager-details__name"
              >
                Monty Bayer
              </Heading>
              <Text color="#044606" className="manager-details__position">
                Executive Board Chairman, Interim-CEO
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Mr. Bayer currently serves as Interim CEO and Executive Chairman
                of the Ostara Board of Directors as well as a managing partner
                with Grosvenor Food and AgTech. He previously served as
                President and CEO of Actagro through March of 2019 and prior to
                that, as Corporate Vice President of Venture Capital, Business
                Development and Technology Licensing for Dow Chemical.
                Additionally, Mr. Bayer held multiple commercial roles for Dow
                AgroSciences in North America, Japan and South Korea. Mr. Bayer
                also serves as a board member for Enterra, Vytelle and Grosvenor
                Food and AgTech.
              </Text>
            </div>
            <StaticImage
              className="manager-details__img desktop"
              placeholder="transparent"
              src="../../images/monty.jpg"
              alt=""
            />
          </Box>
        </Container>

        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default MontyBayer;
